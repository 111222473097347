<template>
	<div class="input-wrapper">
		<div>
			<div class="title" v-if="title">
				{{ title }}
				<span v-if="subtitle">
					<br />
					{{ subtitle }}
				</span>
			</div>
			<multiselect
				:value="value"
				:max-height="200"
				:max="3"
				:options="formList"
				:preserve-search="true"
				:multiple="multiple"
				:close-on-select="!multiple"
				:disabled="disabled"
				:placeholder="placeholder"
				clear
				track-by="id"
				label="name"
				v-bind:required="required"
				class="multiselect__check"
				@input="update"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
				<template slot="noResult">{{ $t('components.not_found') }}</template>
				<template slot="noOptions">{{ $t('components.not_found') }}</template>
				<template v-if="placeholder" slot="placeholder">{{ placeholder }}</template>
				<template v-else slot="placeholder">{{ $t('placeholder.choose') }}</template>
			</multiselect>
			<input type="hidden" :name="name" :value="value.id" v-if="value && !multiple" />
			<input type="hidden" :name="name" :value="'[' + idValues(value) + ']'" v-else-if="value" />
		</div>
		<div v-if="errors && !errors.required" class="input-required">
			{{ $t('system_message.required_message') }}
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Multiselect from 'vue-multiselect'

export default {
	name: 'PondList',
	components: {
		Multiselect,
	},
	props: {
		modelValue: {
			type: [Number, Array],
			default: null,
		},
		categoryValue: {
			type: [Number, Array],
			default: null,
		},
		confinesValue: {
			type: Array,
			default: null,
		},
		errors: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: 'text',
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		userBind: {
			type: Array,
			default: Array,
		},
		allOptions: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue', 'update:modelObjectsName'],
	watch: {
		categoryValue() {
			this.$emit('update:modelValue', null)
		},
	},
	computed: {
		...mapGetters(['_getPondList']),
		value() {
			if (this.multiple) {
				if (!this.modelValue?.length) return []

				return this.modelValue?.map((id) => {
					return this._getPondList.find((e) => e.id === id)
				})
			}

			return this._getPondList.find((e) => e.id === this.modelValue)
		},
		formList() {
			if (this.allOptions) {
				return [
					{ id: null, name: 'Все...' },
					...(this.confinesValue ? this.confinesValue : this._getPondList),
				]
			}

			return this.confinesValue ? this.confinesValue : this._getPondList
		},
	},
	beforeMount() {
		this._fetchPondList()
	},
	methods: {
		...mapActions(['_fetchPondList']),
		update(value) {
			if (value && !this.multiple) {
				this.$emit('update:modelValue', value.id)
				this.$emit('update:modelObjectsName', value.name)
			} else if (value) {
				this.$emit('update:modelValue', this.idValues(value))
				this.$emit('update:modelObjectsName', this.idNames(value))
			} else {
				this.$emit('update:modelValue', null)
			}
		},
		idNames(array) {
			return array.map((element) => element.name)
		},
		idValues(array) {
			return array.map((element) => element.id)
		},
	},
}
</script>

<style>
.title {
	font-weight: 600;
	font-size: 12px;
	color: #0a091d;
	margin-bottom: 8px;
	line-height: 1.2;
}
.title span {
	color: #708295;
}
.input-body .multiselect__tags {
	padding-left: 20px;
	border: 1px solid #0a091d;
	border-radius: 5px;
	background: #ffffff;
	font-weight: 400;
	font-size: 14px;
	color: #0a091d;
	line-height: 1.2;
	width: 100%;
}
.input-body .multiselect--disabled .multiselect__tags {
	border: 1px solid #d3e7fb;
}
.input-body .multiselect--disabled .multiselect__placeholder {
	margin-bottom: 0;
}
.input-body .multiselect__single {
	margin-bottom: 0;
}
.input-body--error.input-body .multiselect__tags {
	border-color: #f3574d;
}
.input-body--error + .input-required {
	display: block;
}
</style>
